// 団体扱・集団扱等の情報用
export const officeData = [
  { key: 'relationshipName', value: '', titleLineFlg: false },
  { key: 'employmentName', value: '', titleLineFlg: false },
  { key: 'insuranceUserRelationshipName', value: '', titleLineFlg: false },
  { key: 'deductionStartDateText', value: '', titleLineFlg: false },
  { key: 'classificationName', value: '', titleLineFlg: false },
  { key: 'collectMoneyName', value: '', titleLineFlg: false },
  { key: 'referenceDateText', value: '', titleLineFlg: false },
  { key: 'retirementDateText', value: '', titleLineFlg: false },
  { key: 'firstInsurancePrice', value: '', titleLineFlg: false },
  { key: 'insurancePrice', value: '', titleLineFlg: false },
  { key: 'loanEndDateText', value: '', titleLineFlg: false },
  { key: 'creditorCode', value: '', titleLineFlg: false },
];

// 証券・約款の確認方法用
export const securitiesAndTermsConfirmData = {
  webSecurity: '',
  documentSecurity: '',
  webAgreement: '',
  documentAgreement: '',
  webExpireGuidance: '',
  documentExpireGuidance: '',
  contractUserAddress: '',
  addressDesignat: '',
};

// 証券等送付先用
export const securitiesDeliveryAddressData = {
 securitiesDeliveryAddress: '',
};

// 第2連絡先の登録用
export const secondContactRegistrationData = [
  { key: 'addressRegisteredText', value: '', titleLineFlg: false },
  { key: 'secondFullNameKanji', value: '', titleLineFlg: false },
  { key: 'secondFullNameKana', value: '', titleLineFlg: false },
  { key: 'secondAddressKanji', value: '', titleLineFlg: false },
  { key: 'secondDateOfBirth', value: '', titleLineFlg: false },
  { key: 'secondGender', value: '', titleLineFlg: false },
  { key: 'secondPhoneNumber1', value: '', titleLineFlg: false },
  { key: 'secondPhoneNumber2', value: '', titleLineFlg: false },
  { key: 'relationshipWithContractor', value: '', titleLineFlg: false },
];

export const contractAgencyStaffData = {
  staffName: ''
};
